<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_contacts = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                            'labels': {value: "", matchMode: FilterMatchMode.CONTAINS}})

// data
const contacts = ref([])
const contact = ref(null)

onMounted( async () => {
    get_contacts()
})

const get_contacts = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-contacts')
    .then(response => {
        console.log(response.data)
        contacts.value = response.data
        loader.value = false
    })
}


const new_contact = () => {
    console.log('new contact')
}

const contact_details = (event) => {
    console.log(event.data)
}

const filter_type = (type) => {
    filter_contacts.value.labels.value = type
    console.log(filter_contacts.value)
}

const types = [
    {name: 'Kunde', value: 10},
    {name: 'Broker', value: 28}
]

const count_types = (type) => {
    return contacts.value.filter(contact => contact.labels.includes(type)).length
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button v-for="type in types" :key="type" rounded :label="type.name" @click="filter_type(type.value)" :badge="String(count_types(type.value))" badgeSeverity="contrast" class="mr-2 bg-black-alpha-90 border-black-alpha-70" />
        </template>
        <template #end>
            <Button label="Export" icon="pi pi-file-excel" v-tooltip.top="'Exportieren'" class="mr-2" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_contacts['global'].value" placeholder="Suche" class="w-full" />
            </span>
            <Button label="Aktualisieren" icon="pi pi-refresh" @click="get_deals()" class="w-auto mr-2" v-tooltip.right="'Aktualisieren'" />
        </template>
        <template #end>
            <Button label="Neuer Kontakt" icon="pi pi-play" @click="new_contact()" class="w-auto mr-2 bg-green-700 text-white border-green-600" v-tooltip.bottom="'Neuer Kontakt erfassen'" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
        <DataTable v-model:filters="filter_contacts" @row-click="contact_details" :value="contacts" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true">
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="name" header="Name">
                <template #body="slotProps">
                    <Avatar icon="pi pi-user" shape="circle" class="mr-2 bg-primary text-white" />
                    <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                </template>
            </Column>
            <Column header="Typ" sortable>
                <template #body="slotProps">
                    <template v-for="label in slotProps.data.labels" :key="label">
                        <Chip v-if="label == 10" class="mr-2 mb-1 bg-blue-700 text-white" label="Kunde" />
                        <Chip v-if="label == 28" class="mr-2 mb-1 bg-orange-700 text-white" label="Broker" />
                    </template>
                </template>
            </Column>
            <Column field="company" header="Firma" sortable>
                <template #body="slotProps">
                    <Chip icon="pi pi-building" class="mr-2 mb-1" :label="slotProps.data.company" />
                </template>
            </Column>
            <Column field="broker" header="co" sortable>
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.broker" icon="pi pi-lock" class="mr-2 mb-1 bg-red-700 text-white" :label="slotProps.data.broker" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>